import React from "react";
import useGameStore from "../../lib/store/GameStore";
import { trackEvent } from "../../lib/utils/analytics";

export function ShareButton({ group }) {
  const [setIsOpen, setShareGroup, shareGroup, setShareJpegData] = useGameStore(
    (state) => [
      state.setIsShareOpen,
      state.setShareGroup,
      state.shareGroup,
      state.setShareJpegData,
    ],
  );
  const [currentPick] = useGameStore((state) => [state.currentPick]);

  function handleClick() {
    if (shareGroup !== group) {
      setShareJpegData(null);
    }
    setShareGroup(group);

    setIsOpen(true);

    trackEvent("show_share_dialog");
  }

  return (
    <button
      onClick={handleClick}
      disabled={currentPick <= group * 5}
      className="inline-flex items-center rounded-lg bg-chartreuse-400 px-5 py-2.5 text-center text-md font-medium text-black hover:bg-chartreuse-500 disabled:opacity-50"
    >
      <svg
        aria-hidden="true"
        className="-ml-1 mr-2 h-5 w-5"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M13 14h-2a8.999 8.999 0 00-7.968 4.81A10.136 10.136 0 013 18C3 12.477 7.477 8 13 8V3l10 8-10 8v-5z" />
      </svg>
      Share
    </button>
  );
}
