import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  submit(event) {
    // Find the closest form element from the checkbox
    const form = this.element.closest("form");
    if (form) {
      form.requestSubmit();
    }
  }
}
