import React, { useEffect } from "react";
import useGameStore from "../../lib/store/GameStore";
import { BaseDialog } from "../../../global/components/BaseDialog";
import { htmlToJpegDataUrl } from "../../../global/lib/utils/htmlToJpegDataUrl";
import { shareImage } from "../../../global/lib/utils/shareImage";
import { Spinner } from "../../../global/components/icons/Spinner";
import { download } from "../../../global/lib/utils/download";
import { trackEvent } from "../../lib/utils/analytics";

function ShareDialog({ modalTitle = "How to Play Gridley" }) {
  const [isOpen, setIsOpen] = useGameStore((state) => [
    state.isShareOpen,
    state.setIsShareOpen,
  ]);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <BaseDialog
        isOpen={isOpen}
        closeModal={closeModal}
        modalTitle=""
        modalBody={<ModalBody />}
        size="lg"
        showClose={true}
      />
    </>
  );
}

function ModalBody({ pick, currentPick }) {
  const [user, setShowShareDiv, jpegData, setJpegData, shareMode, shareGroup] =
    useGameStore((state) => [
      state.user,
      state.setShowShareDiv,
      state.shareJpegData,
      state.setShareJpegData,
      state.shareMode,
      state.shareGroup,
    ]);

  async function shareJpeg() {
    if (shareMode === "share") {
      try {
        trackEvent("share_image");
        await shareImage(jpegData, "predictions.jpg");
      } catch (err) {
        console.error(err);
      }
    } else {
      trackEvent("download_image");
      download(jpegData);
    }
  }

  useEffect(() => {
    async function generateShareImage() {
      try {
        await setShowShareDiv(true);

        const jpegData = await htmlToJpegDataUrl();
        setJpegData(jpegData);
      } catch (err) {
        console.error(err);
      }
      setShowShareDiv(false);
    }

    if (!jpegData) {
      generateShareImage();
    }
  }, []);

  return (
    <>
      <div className="text-center text-black">
        <h3 className="mb-6 text-2xl font-semibold dark:text-gray-400">
          {user ? "Picks Submitted" : "Don't Lose Your Picks!"}
        </h3>

        <div className="mb-6 flex h-64 items-center justify-center">
          {jpegData ? (
            <img
              className="h-64"
              src={jpegData}
              alt={`My Captains Challenge share image`}
            />
          ) : (
            <Spinner className="inline h-8 w-8 animate-spin fill-lime-600 text-gray-200 dark:text-gray-600" />
          )}
        </div>
        {!user && (
          <>
            <p className="mb-6 font-normal text-black dark:text-gray-400 md:mx-0 md:px-8">
              The hard part is done, but to save your picks &amp; join the
              leaderboard{" "}
              <span className="font-semibold">make sure you sign in</span>.
            </p>
            <div className="flex flex-col items-center justify-center pb-2 sm:flex-row sm:space-x-4">
              <a
                href={`login`}
                className="order-last block w-full rounded-lg border bg-white px-5 py-2.5 text-center text-md font-semibold text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 sm:order-first sm:w-auto"
              >
                Sign In
              </a>
              <a
                href={`register`}
                className="order-first mb-2 block w-full rounded-lg bg-chartreuse-400 px-5 py-2.5 text-center text-md font-semibold text-black hover:bg-chartreuse-500 focus:outline-none focus:ring-2 focus:ring-chartreuse-500 focus:ring-offset-2 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 sm:order-last sm:mb-0 sm:w-auto"
              >
                Create Account
              </a>
            </div>
          </>
        )}
        {user && shareMode && (
          <>
            <p className="mb-6 font-normal text-black dark:text-gray-400 md:mx-0 md:px-8">
              Nice work! Share your picks, then check back during the round for
              live scores and ranks.
            </p>

            <p className="mb-6 text-black dark:text-gray-400">
              <button
                disabled={!jpegData}
                onClick={shareJpeg}
                className="mx-auto flex items-center rounded-lg bg-chartreuse-400 px-6 py-3 text-center text-md font-medium text-black hover:bg-chartreuse-500 disabled:opacity-50 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                {shareMode === "share" ? "Share" : "Download"} Image
              </button>
            </p>
          </>
        )}
      </div>
    </>
  );
}

export default ShareDialog;
