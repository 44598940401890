import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "startRound",
    "primaryFinalsType",
    "secondaryFinalsType",
    "validationMessage",
  ];

  connect() {
    // Initialize available rounds data from the select element
    this.availableRounds = this.getAvailableRounds();
    this.finalsSystemsData = this.getFinalsSystemsData();

    // Add validation message element if it doesn't exist
    if (!this.hasValidationMessageTarget) {
      const messageElement = document.createElement("div");
      messageElement.classList.add("text-red-500", "text-sm", "mt-2", "hidden");
      messageElement.dataset.finalsSystemTarget = "validationMessage";
      this.startRoundTarget.parentNode.appendChild(messageElement);
    }

    // Validate initial state
    this.validateFinalsDuration();
  }

  getAvailableRounds() {
    // Extract round information from the select options
    const rounds = {};
    this.startRoundTarget.querySelectorAll("option").forEach((option) => {
      if (option.value) {
        rounds[option.value] = parseInt(
          option.textContent.match(/\d+/)[0] || 0,
        );
      }
    });
    return rounds;
  }

  getFinalsSystemsData() {
    // Map finals systems to their week requirements
    const data = {};
    this.primaryFinalsTypeTarget
      .querySelectorAll("option")
      .forEach((option) => {
        const value = option.value;
        const text = option.textContent;

        // Extract weeks from text (format: "X Teams, Y Weeks")
        const weeksMatch = text.match(/(\d+) Weeks?$/);
        if (weeksMatch) {
          data[value] = parseInt(weeksMatch[1]);
        } else if (text === "None") {
          data[value] = 0;
        }
      });
    return data;
  }

  onFinalsStartRoundChange() {
    this.validateFinalsDuration();
  }

  onPrimaryFinalsTypeChange() {
    this.validateFinalsDuration();
  }

  validateFinalsDuration() {
    const selectedRoundValue = this.startRoundTarget.value;
    const primaryFinalsValue = this.primaryFinalsTypeTarget.value;
    const secondaryFinalsValue = this.secondaryFinalsTypeTarget.value;

    // Clear previous validation message
    this.hideValidationMessage();

    // Skip validation if no round selected or finals type is None
    if (!selectedRoundValue || primaryFinalsValue === "NONE") {
      return;
    }

    // Get selected round number
    const selectedRoundOption = this.startRoundTarget.querySelector(
      `option[value="${selectedRoundValue}"]`,
    );
    const startRoundNumber = parseInt(
      selectedRoundOption.textContent.match(/\d+/)[0] || 0,
    );

    // Get last round number from the available options
    const allRoundOptions = Array.from(
      this.startRoundTarget.querySelectorAll("option"),
    ).filter((option) => option.value);
    const lastOptionText =
      allRoundOptions[allRoundOptions.length - 1].textContent;
    const lastRoundNumber = parseInt(lastOptionText.match(/\d+/)[0] || 0);

    // Calculate required weeks
    const primaryWeeks = this.finalsSystemsData[primaryFinalsValue] || 0;
    const secondaryWeeks = this.finalsSystemsData[secondaryFinalsValue] || 0;
    const requiredWeeks = Math.max(primaryWeeks, secondaryWeeks);

    // Calculate available weeks
    const availableWeeks = lastRoundNumber - startRoundNumber + 1;

    // Validate that finals fit within available rounds
    if (requiredWeeks > 0 && availableWeeks < requiredWeeks) {
      this.showValidationMessage(
        `The selected finals systems require ${requiredWeeks} weeks, but there are only ${availableWeeks} weeks available from the selected start round (Round ${startRoundNumber}).`,
      );
    }
  }

  showValidationMessage(message) {
    if (this.hasValidationMessageTarget) {
      this.validationMessageTarget.textContent = message;
      this.validationMessageTarget.classList.remove("hidden");
    }
  }

  hideValidationMessage() {
    if (this.hasValidationMessageTarget) {
      this.validationMessageTarget.classList.add("hidden");
    }
  }
}
