import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // Find the scoreboard elements
    this.leftScoreboard = document.getElementById("scoreboard-left");
    this.rightScoreboard = document.getElementById("scoreboard-right");
    this.middleScoreboard = document.getElementById("scoreboard-middle");
    this.teamStatsMobile = document.getElementById("team-stats-mobile");
    this.lineupView = document.getElementById("lineup-view");
    this.scoresView = document.getElementById("scores-view");

    // Add click event listeners
    this.leftScoreboard.addEventListener("click", this.showLeftTeam.bind(this));
    this.rightScoreboard.addEventListener(
      "click",
      this.showRightTeam.bind(this),
    );
    this.middleScoreboard.addEventListener("click", this.showMiddle.bind(this));

    // Add resize event listener
    this.handleResize = this.handleWindowResize.bind(this);
    window.addEventListener("resize", this.handleResize);

    // Track current viewport state
    this.isCurrentlySmallViewport = this.isSmallViewport();

    // Initialize view based on current viewport size
    if (this.isCurrentlySmallViewport) {
      // Default to showing both teams on mobile
      this.showMiddle({ preventDefault: () => {} });
    }
  }

  disconnect() {
    // Clean up event listeners on disconnect
    if (this.leftScoreboard) {
      this.leftScoreboard.removeEventListener(
        "click",
        this.showLeftTeam.bind(this),
      );
    }
    if (this.rightScoreboard) {
      this.rightScoreboard.removeEventListener(
        "click",
        this.showRightTeam.bind(this),
      );
    }
    if (this.middleScoreboard) {
      this.middleScoreboard.removeEventListener(
        "click",
        this.showMiddle.bind(this),
      );
    }

    // Remove resize listener
    window.removeEventListener("resize", this.handleResize);
  }

  // Check if we're on a small viewport (below md: breakpoint)
  isSmallViewport() {
    // Tailwind's md breakpoint is typically 768px
    return window.innerWidth < 768;
  }

  // Get all relevant table cells by their data-col-type attribute
  getColumnsByType(type) {
    return document.querySelectorAll(`[data-col-type="${type}"]`);
  }

  // Handle window resize events
  handleWindowResize() {
    const isSmall = this.isSmallViewport();

    // Only trigger changes when crossing the mobile/desktop threshold
    if (isSmall !== this.isCurrentlySmallViewport) {
      // Update our state tracker
      this.isCurrentlySmallViewport = isSmall;

      // Apply appropriate view based on new viewport size
      if (!isSmall) {
        this.resetView();
      } else {
        this.showMiddle({ preventDefault: () => {} });
      }
    }
  }

  // Reset view to show all columns (for desktop)
  resetView() {
    // Show all cells
    this.getColumnsByType("left").forEach((cell) => {
      cell.style.display = "";
    });

    this.getColumnsByType("right").forEach((cell) => {
      cell.style.display = "";
    });

    this.getColumnsByType("lineup-pos").forEach((cell) => {
      cell.style.display = "";
    });

    // Hide team stats mobile element
    this.scoresView.style.display = "none";
    this.lineupView.style.display = "block";

    // Reset opacity on scoreboard elements
    const fadedClass = "opacity-50";
    this.leftScoreboard.classList.remove(fadedClass);
    this.rightScoreboard.classList.remove(fadedClass);
    this.middleScoreboard.classList.remove(fadedClass);
  }

  // Show left team, hide right team and position column
  showLeftTeam(event) {
    // Only proceed and prevent default if we're on a small viewport
    if (!this.isSmallViewport()) return;

    event.preventDefault();

    // Show left team cells
    this.getColumnsByType("left").forEach((cell) => {
      cell.style.display = "";
    });

    // Hide right team cells and position column
    this.getColumnsByType("right").forEach((cell) => {
      cell.style.display = "none";
    });

    this.getColumnsByType("lineup-pos").forEach((cell) => {
      cell.style.display = "none";
    });

    // Hide team stats mobile element
    this.scoresView.style.display = "none";
    this.lineupView.style.display = "block";

    // Add active class to left scoreboard
    this.setActiveScoreboard("left");
  }

  // Show right team, hide left team and position column
  showRightTeam(event) {
    // Only proceed and prevent default if we're on a small viewport
    if (!this.isSmallViewport()) return;

    event.preventDefault();

    // Show right team cells
    this.getColumnsByType("right").forEach((cell) => {
      cell.style.display = "";
    });

    // Hide left team cells and position column
    this.getColumnsByType("left").forEach((cell) => {
      cell.style.display = "none";
    });

    this.getColumnsByType("lineup-pos").forEach((cell) => {
      cell.style.display = "none";
    });

    // Hide team stats mobile element
    this.scoresView.style.display = "none";
    this.lineupView.style.display = "block";

    // Add active class to right scoreboard
    this.setActiveScoreboard("right");
  }

  // Show middle (team stats mobile), hide both teams and position column
  showMiddle(event) {
    // Only proceed and prevent default if we're on a small viewport
    if (!this.isSmallViewport()) return;

    event.preventDefault();

    // Hide position column
    this.getColumnsByType("lineup-pos").forEach((cell) => {
      cell.style.display = "none";
    });

    // Hide both team cells
    this.getColumnsByType("left").forEach((cell) => {
      cell.style.display = "none";
    });

    this.getColumnsByType("right").forEach((cell) => {
      cell.style.display = "none";
    });

    // Hide team stats mobile element
    this.scoresView.style.display = "block";
    this.lineupView.style.display = "none";

    // Add active class to middle scoreboard
    this.setActiveScoreboard("middle");
  }

  // Helper method to set active class on scoreboard elements
  setActiveScoreboard(active) {
    const fadedClass = "opacity-50";

    // If middle is active, remove faded class from all scoreboards
    if (active === "middle") {
      this.leftScoreboard.classList.remove(fadedClass);
      this.rightScoreboard.classList.remove(fadedClass);
      this.middleScoreboard.classList.remove(fadedClass);
      return;
    }

    // Otherwise, apply faded class to inactive scoreboards
    this.leftScoreboard.classList.toggle(fadedClass, active !== "left");
    this.rightScoreboard.classList.toggle(fadedClass, active !== "right");
    this.middleScoreboard.classList.toggle(fadedClass, active !== "middle"); // Middle always stays at full opacity
  }
}
