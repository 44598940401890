import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["messageBox", "actionBar", "acceptButton"];

  connect() {
    // Ensure targets are connected
    if (!this.hasMessageBoxTarget || !this.hasActionBarTarget) return;
  }

  messageBoxFocused() {
    this.actionBarTarget.classList.add("hidden");
  }

  messageBoxBlurred() {
    this.actionBarTarget.classList.remove("hidden");
  }

  confirmAccept(event) {
    if (!confirm("Are you sure you want to accept this trade?")) {
      event.preventDefault();
    }
  }
}
