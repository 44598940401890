import React from "react";
import { trackEvent } from "../../lib/utils/analytics";
import useGameStore from "../../lib/store/GameStore";
import { IconUndo } from "../../../global/components/icons/IconUndo";

export function ResetButton() {
  const [resetPicks] = useGameStore((state) => [state.resetPicks]);

  function handleClick() {
    resetPicks();

    trackEvent("reset_board");
  }

  return (
    <button
      onClick={handleClick}
      type="button"
      className="inline-flex items-center rounded-lg bg-white/20 px-5 py-2.5 text-center text-md font-medium text-white hover:bg-white/30"
    >
      <IconUndo className="-ml-1 mr-2 h-5 w-5 text-white" />
      Reset
    </button>
  );
}
